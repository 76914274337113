// 集团端路由-结算中心
const settlementGroupRouter = [
  {
    // 结算中心-首页
    path: 'settlement',
    name: 'settlementCenter',
    meta: {
      permissionCode: 'cp-settlementCenter',
      routerType: 2,
    },
    component: () => import('../views/group-management/settlement-center/index.vue'),
  },
  {
    // 结算中心-应付账
    path: 'settlement/billlist/:accountId',
    name: 'settlementBillList',
    meta: {
      permissionCode: 'cp-settlementCenter',
      routerType: 2,
    },
    component: () => import('../views/group-management/settlement-center/bill-list/index.vue'),
  },
  {
    // 结算中心-应付账-账单列表
    path: 'settlement/billOrderList/:hotelId/:billId/:orderType/:billDate',
    name: 'billOrderList',
    meta: {
      permissionCode: 'cp-settlementCenter',
      routerType: 2,
    },
    component: () => import('../views/group-management/settlement-center/bill-order-list/index.vue'),
  },
  {
    // 结算中心-应收账
    path: 'settlement/receivableList/:accountId',
    name: 'receivableList',
    meta: {
      permissionCode: 'cp-settlementCenter',
      routerType: 2,
    },
    component: () => import('../views/group-management/settlement-center/receivable-bill/index.vue'),
  },
  {
    // 结算中心-应收账-管理费详单
    path: 'settlement/manageFeeDetail/:receivableId/:status',
    name: 'manageFeeDetail',
    meta: {
      permissionCode: 'cp-settlementCenter',
      routerType: 2,
    },
    component: () => import('../views/group-management/settlement-center/receivable-bill/manageFeeDetail.vue'),
  },
  {
    // 结算中心，客房订单详情
    path: 'settlement/settleOrderDetail',
    name: 'settleOrderDetail',
    meta: {
      permissionCode: 'cp-settlementCenter',
      routerType: 2,
    },
    component: () => import('../views/hotel-management/order-list/new-order-detail/index.vue'),
  },
  {
    // 结算中心，对账单审核
    path: 'statementReview',
    name: 'statementReview',
    meta: {
      permissionCode: 'cp-statementReview',
      routerType: 2,
    },
    component: () => import('../views/group-management/settlement-center/statement-review/index.vue'),
  },
  {
    // 结算中心，对账单审核明细
    path: 'statementReview/detail/:hotelId/:hotelName/:statementId/:checkStatus/:createDate/:endDate/:statementType?',
    name: 'statementReviewDetail',
    meta: {
      permissionCode: 'cp-statementReview',
      routerType: 2,
    },
    component: () => import('../views/group-management/settlement-center/statement-review/detail/index.vue'),
  },
  {
    // 交叉奖励配置
    path: 'crossBonusSetting',
    name: 'crossBonusSetting',
    meta: {
      permissionCode: 'cp-group-crossBonusSetting',
      routerType: 2,
    },
    component: () => import('../views/group-management/settlement-center/cross-bonus-setting/index.vue'),
  },
  {
    // 酒店管理-账务信息维护
    path: 'importSettlementAccounts',
    name: 'importSettlementAccounts',
    meta: {
      permissionCode: 'cp-hotelManage',
      routerType: 2,
    },
    component: () => import('../views/group-management/account-manager/children/import-settlement-accounts.vue'),
  },
  {
    // 分销预付对账单
    path: 'distributionAccountStatement',
    name: 'distributionAccountStatement',
    meta: {
      permissionCode: 'cp-distributionBill',
      routerType: 2,
      keepAlive: true,
      deepth: 1,
    },
    component: () => import('../views/group-management/settlement-center/distribution-bill/index.vue'),
  },
  {
    // 河图配置
    // 分销预付-佣金账单管理
    // 权限代码：el-group-ui-prepaidCommission
    // URL地址  /settlement-order/prepaidCommission
    // 上级菜单 分销账单（三级）
    path: 'prepaidCommission',
    name: 'prepaidCommission',
    meta: {
      permissionCode: 'cp-distributionBill',
      routerType: 2,
      keepAlive: true,
      deepth: 1,
    },
    component: () => import('../views/group-management/settlement-center/distribution-bill/prepaid-commission-bill/index.vue'),
  },
  {
    // 分销预付账单管理
    path: 'billManagement',
    name: 'billManagement',
    meta: {
      permissionCode: 'cp-distributionBill',
      routerType: 2,
    },
    component: () => import('../views/group-management/settlement-center/bill-management/index.vue'),
    children: [{
      // 渠道账单
      path: 'channelBill',
      name: 'channelBill',
      meta: {
        permissionCode: 'cp-distributionBill',
        routerType: 2,
      },
      component: () => import('../views/group-management/settlement-center/bill-management/channel-bill/index.vue'),
    }, {
      // 渠道账单详情
      path: 'channelBillInfo/:statementDate',
      name: 'channelBillInfo',
      meta: {
        permissionCode: 'cp-distributionBill',
        routerType: 2,
      },
      component: () => import('../views/group-management/settlement-center/bill-management/channel-bill-info/index.vue'),
    }, {
      // 发送账单给酒店
      path: 'sendHotelBill/:statementDate',
      name: 'sendHotelBill',
      meta: {
        permissionCode: 'cp-distributionBill',
        routerType: 2,
      },
      component: () => import('../views/group-management/settlement-center/bill-management/send-hotel-bill/index.vue'),
    }, {
      // 发送账单给酒店历史
      path: 'sendHotelBillHistory/:statementDate',
      name: 'sendHotelBillHistory',
      meta: {
        permissionCode: 'cp-distributionBill',
        routerType: 2,
      },
      component: () => import('../views/group-management/settlement-center/bill-management/send-hotel-bill-history/index.vue'),
    }, {
      // 酒店账单汇总列表
      path: 'hotelBillSummary',
      name: 'hotelBillSummary',
      meta: {
        permissionCode: 'cp-distributionBill',
        routerType: 2,
      },
      component: () => import('../views/group-management/settlement-center/bill-management/hotel-bill-summary/index.vue'),
    }, {
      // 酒店账单
      path: 'hotelBill/:statementDate/:statementDate2/:statementStatus',
      name: 'hotelBill',
      meta: {
        permissionCode: 'cp-distributionBill',
        routerType: 2,
      },
      component: () => import('../views/group-management/settlement-center/bill-management/hotel-bill/index.vue'),
    }, {
      // 酒店账单明细
      path: 'hotelBillInfo/:billId/:hotelName/:settleDate/:settleDate2',
      name: 'hotelBillInfo',
      meta: {
        permissionCode: 'cp-distributionBill',
        routerType: 2,
      },
      component: () => import('../views/group-management/settlement-center/bill-management/hotel-bill-info/index.vue'),
    },
    ],
  },
  {
    // 分销现付账单管理
    path: 'currentPayBillManagement',
    name: 'currentPayBillManagement',
    meta: {
      permissionCode: 'cp-distributionBill',
      routerType: 2,
    },
    component: () => import('../views/group-management/settlement-center/current-pay-bill-management/index.vue'),
    children: [
      {
        // 渠道账单
        path: 'currentPayChannelBill',
        name: 'currentPayChannelBill',
        meta: {
          permissionCode: 'cp-distributionBill',
          routerType: 2,
          keepAlive: true,
          deepth: 1,
        },
        component: () => import('../views/group-management/settlement-center/current-pay-bill-management/channel-bill/index.vue'),
      },
      {
        // 渠道账单-明细/:settleDate/:settleAccountName/:statementId/:reconciliationType?
        path: 'currentPayChannelBillDetail',
        name: 'currentPayChannelBillDetail',
        meta: {
          permissionCode: 'cp-distributionBill',
          routerType: 2,
          keepAlive: true,
          deepth: 2,
        },
        component: () => import('../views/group-management/settlement-center/current-pay-bill-management/channel-bill-detail/index.vue'),
      },
      {
        // 渠道账单-导入账单日志
        path: 'currentPayImportBillLog',
        name: 'currentPayImportBillLog',
        meta: {
          permissionCode: 'cp-distributionBill',
          routerType: 2,
          keepAlive: true,
          deepth: 1,
        },
        component: () => import('../views/group-management/settlement-center/current-pay-bill-management/import-bill-log/index.vue'),
      },
      { // 结算中心-订单详情
        path: 'currentPayOrderDetail',
        name: 'currentPayOrderDetail',
        meta: {
          permissionCode: 'cp-distributionBill',
          routerType: 2,
          keepAlive: true,
          deepth: 3,
        },
        component: () => import('../views/hotel-management/order-list/new-order-detail/index.vue'),
      },
      {
        // 酒店账单-调整账单
        path: 'currentPayHotelBillEdit/:from',
        name: 'currentPayHotelBillEdit',
        meta: {
          permissionCode: 'cp-distributionBill',
          routerType: 2,
          keepAlive: true,
          deepth: 2,
        },
        component: () => import('../views/group-management/settlement-center/current-pay-bill-management/hotel-bill-edit/index.vue'),
      },
      {
        // 酒店账单-查看账单
        path: 'currentPayHotelBillInfo/:billDate/:hotelName/:statementId',
        name: 'currentPayHotelBillInfo',
        meta: {
          permissionCode: 'cp-distributionBill',
          routerType: 2,
          keepAlive: true,
          deepth: 1,
        },
        component: () => import('../views/group-management/settlement-center/current-pay-bill-management/hotel-bill-info/index.vue'),
      },
      {
        // 酒店账单汇总列表
        path: 'currentPayHotelBillSummary',
        name: 'currentPayHotelBillSummary',
        meta: {
          permissionCode: 'cp-distributionBill',
          routerType: 2,
          keepAlive: true,
          deepth: 1,
        },
        component: () => import('../views/group-management/settlement-center/current-pay-bill-management/hotel-bill-summary/index.vue'),
      },
    ],
  },
  {
    // 官网分销账单管理
    path: 'officialWebsiteDistributionBill',
    name: 'officialWebsiteDistributionBill',
    meta: {
      permissionCode: 'cp-officialWebsiteDistributionBill',
      routerType: 2,
      keepAlive: true,
      deepth: 1,
    },
    component: () => import('../views/group-management/settlement-center/official-website-distribution-bill/index.vue'),
  },
  {
    // 官网分销酒店账单明细
    path: 'websiteDistributionBillDetail',
    name: 'websiteDistributionBillDetail',
    meta: {
      permissionCode: 'cp-officialWebsiteDistributionBill',
      routerType: 2,
      keepAlive: true,
      deepth: 2,
    },
    component: () => import('../views/group-management/settlement-center/official-website-distribution-bill/website-distribution-bill-detail.vue'),
  },
  {
    // 调整账单
    path: 'adjustBillPage',
    name: 'adjustBillPage',
    meta: {
      routerType: 2,
      deepth: 2,
      isNotShowTab: true,
    },
    component: () => import('../views/group-management/settlement-center/official-website-distribution-bill/adjust-bill-page.vue'),
  },
  {
    // 账单结算账户
    path: 'billMail',
    name: 'billMail',
    meta: {
      permissionCode: 'cp-distributionBill',
      routerType: 2,
    },
    component: () => import('../views/group-management/settlement-center/bill-mail/index.vue'),
  },
  {
    // 汇率配置
    path: 'exchangeRateConfig',
    name: 'exchangeRateConfig',
    meta: {
      permissionCode: 'cp-exchangeRateConfig',
      routerType: 2,
    },
    component: () => import('../views/group-management/settlement-center/exchange-rate-config/index.vue'),
  },
  {
    // 查看/调整账单
    path: 'adjustBill',
    name: 'adjustBill',
    meta: {
      permissionCode: 'cp-distributionBill',
      routerType: 2,
      deepth: 2,
    },
    component: () => import('../views/group-management/settlement-center/distribution-bill/adjust-bill/index.vue'),
  },
  {
    // 调整非本期账单
    path: 'adjustOtherBill',
    name: 'adjustOtherBill',
    meta: {
      permissionCode: 'cp-distributionBill',
      routerType: 2,
      keepAlive: true,
      deepth: 1,
    },
    component: () => import('../views/group-management/settlement-center/distribution-bill/adjust-bill/adjust-other-bill/index.vue'),
  },
  {
    // 编辑账单
    path: 'editBill',
    name: 'editBill',
    meta: {
      permissionCode: 'cp-distributionBill',
      routerType: 2,
      deepth: 2,
    },
    component: () => import('../views/group-management/settlement-center/distribution-bill/adjust-bill/edit-bill/index.vue'),
  },
  {
    // 账单导入历史
    path: 'importBillHistory',
    name: 'importBillHistory',
    meta: {
      permissionCode: 'cp-distributionBill',
      routerType: 2,
    },
    component: () => import('../views/group-management/settlement-center/distribution-bill/import-bill-history/index.vue'),
  },
  {
    // 结算中心，分销对账单审核
    path: 'distributionStatementReview',
    name: 'distributionStatementReview',
    meta: {
      permissionCode: 'cp-statementReview',
      routerType: 2,

    },
    component: () => import('../views/group-management/settlement-center/distribution-statement-review/index.vue'),
  },
  {
    // 结算中心，分销对账单审核-酒店审核状态列表
    path: 'distributionStatementReviewStatus/:settleDate',
    name: 'distributionStatementReviewStatus',
    meta: {
      permissionCode: 'cp-statementReview',
      routerType: 2,
      keepAlive: true,
      deepth: 1,
    },
    component: () => import('../views/group-management/settlement-center/distribution-statement-review/statement-status/index.vue'),
  },
  {
    // 结算中心，合作伙伴账单-佣金账单
    path: 'commissionStatement',
    name: 'commissionStatement',
    meta: {
      permissionCode: 'cp-statementReview',
      routerType: 2,

    },
    component: () => import('../views/group-management/settlement-center/partner-bill/index.vue'),
  },
  // {
  //   // 结算中心，合作伙伴账单-分佣账单
  //   path: 'getPartnerCommission',
  //   name: 'getPartnerCommission',
  //   meta: {
  //     permissionCode: 'cp-statementReview',
  //     routerType: 2,

  //   },
  //   component: () => import('../views/group-management/settlement-center/partner-bill/partner-commission.vue'),
  // },
  {
    // 结算中心，合作伙伴账单-账单详情
    path: 'commissionInfo',
    name: 'commissionInfo',
    meta: {
      permissionCode: 'cp-statementReview',
      routerType: 2,
    },
    component: () => import('../views/group-management/settlement-center/partner-bill/bill-info.vue'),
  },
  {
    // 结算中心，分销对账单审核
    path: 'distributionStatementReviewInfo/:settleDate',
    name: 'distributionStatementReviewInfo',
    meta: {
      permissionCode: 'cp-statementReview',
      routerType: 2,
      deepth: 2,
    },
    component: () => import('../views/group-management/settlement-center/distribution-statement-review/statement-info/index.vue'),
  },
  {
    // 结算中心，抵扣账单，酒店抵扣汇总
    path: 'hotelDeductionTotal',
    name: 'hotelDeductionTotal',
    meta: {
      permissionCode: 'cp-deductionBill',
      routerType: 2,
    },
    component: () => import('../views/group-management/settlement-center/deduction-bill/hotel-deduction-total/index.vue'),
  },


  {
    // 结算中心，抵扣账单，抵扣明细
    path: 'deductionDetail',
    name: 'deductionDetail',
    meta: {
      permissionCode: 'cp-deductionBill',
      routerType: 2,
    },
    component: () => import('../views/group-management/settlement-center/deduction-bill/deduction-detail/index.vue'),
  },
  {
    // 结算中心，抵扣账单，抵扣策略
    path: 'deductionStrategy',
    name: 'deductionStrategy',
    meta: {
      permissionCode: 'cp-deductionBill',
      routerType: 2,
    },
    component: () => import('../views/group-management/settlement-center/deduction-bill/deduction-strategy/index.vue'),
  },
  {
    // 结算中心，抵扣账单，抵扣策略详情
    path: 'deductionStrategyDetail',
    name: 'deductionStrategyDetail',
    meta: {
      permissionCode: 'cp-deductionBill',
      routerType: 2,
    },
    component: () => import('../views/group-management/settlement-center/deduction-bill/deduction-strategy/detail/index.vue'),
  },
  {
    // 结算中心，结算报表，CRS收支明细
    path: 'crsInExDetail',
    name: 'crsInExDetail',
    meta: {
      permissionCode: 'cp-settlementReport',
      routerType: 2,
    },
    component: () => import('../views/group-management/settlement-center/settlement-report/crs-in-ex-detail/index.vue'),
  },
  {
    // 结算中心，结算报表，渠道账单
    path: 'channelBill',
    name: 'channelBill',
    meta: {
      permissionCode: 'cp-settlementReport',
      routerType: 2,
    },
    component: () => import('../views/group-management/settlement-center/settlement-report/channel-bill/index.vue'),
  },
  {
    // 结算中心，酒店收款银行账户
    path: 'hotelBankAccount',
    name: 'hotelBankAccount',
    meta: {
      permissionCode: 'cp-hotelReceivingBank',
      routerType: 2,
    },
    component: () => import('../views/group-management/settlement-center/hotel-receiving-bank/hotel-bank-account/index.vue'),
  },
  {
    // 结算中心，酒店收款银行账户信息
    path: 'hotelBankAccountInfo/:hotelId/:hotelName',
    name: 'hotelBankAccountInfo',
    meta: {
      permissionCode: 'cp-hotelReceivingBank',
      routerType: 2,
    },
    component: () => import('../views/group-management/settlement-center/hotel-receiving-bank/hotel-bank-account-info/index.vue'),
  },
  {
    // 结算中心，酒店收款银行--改为银行代码
    path: 'hotelReceivingBank',
    name: 'hotelReceivingBank',
    meta: {
      permissionCode: 'cp-hotelReceivingBank',
      routerType: 2,
    },
    component: () => import('../views/group-management/settlement-center/hotel-receiving-bank/index.vue'),
  },
  {
    // 结算中心，新增账单收支及发票
    path: 'billIncomeAndInvoice',
    name: 'billIncomeAndInvoice',
    meta: {
      permissionCode: 'cp-billIncomeAndInvoice',
      routerType: 2,
    },
    component: () => import('../views/group-management/settlement-center/bill-income-and-invoice/index.vue'),
  },
  {
    // 账户领款-已领款
    path: 'receivedPayment',
    name: 'receivedPayment',
    meta: {
      permissionCode: 'cp-accountSettlement',
      routerType: 2,
    },
    component: () => import('../views/group-management/settlement-center/account-settlement/received-payment/index.vue'),
  },
  {
    // 待领款
    path: 'accountSettlement',
    name: 'pendingPayment',
    meta: {
      permissionCode: 'cp-accountSettlement',
      routerType: 2,
    },
    component: () => import('../views/group-management/settlement-center/account-settlement/pending-payment/index.vue'),
  },
  {
    // 操作领款
    path: 'accountSettlement/operatePayment',
    name: 'operatePayment',
    meta: {
      permissionCode: 'cp-accountSettlement',
      routerType: 2,
    },
    component: () => import('../views/group-management/settlement-center/account-settlement/operate-payment/index.vue'),
  },
  {
    // 资金流水池
    path: 'capitalFlowPool',
    name: 'capitalFlowPool',
    meta: {
      permissionCode: 'cp-accountSettlement',
      routerType: 2,
    },
    component: () => import('../views/group-management/settlement-center/account-settlement/capital-flow-pool/index.vue'),
  },
];
// 集团端路由-订单
const orderGroupRouter = [
  {
    path: 'groupOrderList',
    name: 'groupOrderList',
    meta: {
      permissionCode: 'cp-orderManage',
      routerType: 2,
      keepAlive: true,
      deepth: 1,
    },
    component: () => import('../views/group-management/group-order/group-order-list.vue'),
  },
  {
    path: 'offlineOrderForm',
    name: 'offlineOrderForm',
    meta: {
      permissionCode: 'cp-orderManage',
      routerType: 2,
      deepth: 2,
      // isNotShowTab:true
    },
    component: () => import('../views/group-management/group-order/offlineOrder/index.vue'),
  },
  {
    path: 'groupOrderList/groupRoomOrderDetail',
    name: 'groupRoomOrderDetail',
    meta: {
      permissionCode: 'cp-orderManage',
      routerType: 2,
      deepth: 2,
    },
    component: () => import('../views/hotel-management/order-list/new-order-detail/index.vue'),
  },
  {
    path: 'feedbackDetail',
    name: 'feedbackDetail',
    meta: {
      permissionCode: 'cp-orderManage',
      routerType: 2,
      keepAlive: true,
      deepth: 3,
    },
    component: () => import('../views/hotel-management/order-list/new-order-detail/feedback-detail/index.vue'),
  },
  {
    path: 'updateDetail',
    name: 'updateDetail',
    meta: {
      permissionCode: 'cp-orderManage',
      routerType: 2,
      deepth: 4,
    },
    component: () => import('../views/hotel-management/order-list/new-order-detail/update-detail/index.vue'),
  },
  {
    path: 'newFeedback',
    name: 'newFeedback',
    meta: {
      permissionCode: 'cp-orderManage',
      routerType: 2,
    },
    component: () => import('../views/hotel-management/order-list/new-order-detail/new-feedback/index.vue'),
  },
  {
    // 会员升级订单
    path: 'groupSaleGoldenCard',
    name: 'groupSaleGoldenCard',
    meta: {
      permissionCode: 'cp-orderManage',
      routerType: 2,
    },
    component: () => import('../views/hotel-management/sale-card/sale-golden-card.vue'),
  },
  {
    path: 'memberCardDetail',
    name: 'memberCardDetail',
    meta: {
      permissionCode: 'cp-orderManage',
      routerType: 2,
    },
    component: () => import('../views/hotel-management/sale-card/member-card-detail'),
  },
  {
    path: 'ticketSetting',
    name: 'ticketSetting',
    meta: {
      permissionCode: 'cp-orderManage',
      routerType: 2,
    },
    component: () => import('../views/hotel-management/ticket-setting/index'),
  },
];
// 集团管理费
const manageFeeRouter = [
  {
    path: 'manageFeeMaintain',
    name: 'manageFeeMaintain',
    meta: {
      permissionCode: 'cp-group-memageFeeMaintain',
      routerType: 2,
    },
    component: () => import('../views/group-management/group-manage-fee/manageFeeMaintain/index'),
  },
  {
    path: 'manageFeeMaintainInfo/:hotelId',
    name: 'manageFeeMaintainInfo',
    meta: {
      permissionCode: 'cp-group-memageFeeMaintain',
      routerType: 2,
    },
    component: () => import('../views/group-management/group-manage-fee/manageFeeMaintainInfo/index.vue'),
  },
  {
    path: 'manageFeeBill',
    name: 'manageFeeBill',
    meta: {
      permissionCode: 'cp-group-memageFeeMaintain',
      routerType: 2,
    },
    component: () => import('../views/group-management/group-manage-fee/manageFeeBill/index.vue'),
  },
];
// 提供给会员的客房订单详情
const memberOrderRouter = [
  {
    // 会员，客房订单详情
    path: 'groupOrderList/memberOrderDetail',
    name: 'memberOrderDetail',
    meta: {
      permissionCode: 'cp-group-memberList',
      routerType: 2,
    },
    component: () => import('../views/hotel-management/order-list/new-order-detail/index.vue'),
  },
  {
    // 卡券关联客房订单详情
    path: 'groupOrderList/couponRelationOrderDetail',
    name: 'couponRelationOrderDetail',
    meta: {
      permissionCode: 'cp-group-memberCoupon',
      routerType: 2,
    },
    component: () => import('../views/hotel-management/order-list/new-order-detail/index.vue'),
  },
  {
    // 平安账户
    path: 'paBankAccount',
    name: 'paBankAccount',
    meta: {
      permissionCode: 'cp-paBankAccountView',
      routerType: 2,
    },
    component: () => import('../views/pingan-management/index.vue'),
  },
];
export default [
  ...settlementGroupRouter,
  ...orderGroupRouter,
  ...manageFeeRouter,
  ...memberOrderRouter,
];
