// crs组前缀
const crsPrefix = '/crs_api';
// 直销组前缀
const rezenPrefix = '/api';
// cbs前缀
const cbsPrefix = '/cbs_api';

const crsApiMapping = {
  // 获取银行信息
  getAllBankInfo: `${crsPrefix}/merchant/hotel/bank/getAllBankInfo`,
  // 获取酒店财务信息
  gethotelbankinfobyhotelid: `${crsPrefix}/merchant/hotel/bank/gethotelbankinfobyhotelid`,
  // 保存财务信息
  savehotelbank: `${crsPrefix}/merchant/hotel/bank/savehotelbank`,
  // 获取管理费列表
  getManagementFeeMaintenanceList: `${crsPrefix}/settle/managementFeeMaintenance/getManagementFeeMaintenanceList`,
  // 设置管理费生效日期
  addManagementFeeEffectiveDate: `${crsPrefix}/settle/managementFeeMaintenance/addManagementFeeEffectiveDate`,
  // 获取管理费详情
  getHotelManagementFeeDetails: `${crsPrefix}/settle/managementFeeMaintenance/getHotelManagementFeeDetails`,
  // 删除管理费
  deleteHotelManageFeeDetails: `${crsPrefix}/settle/managementFeeMaintenance/deleteHotelManageFeeDetails`,
  // 新增管理费
  insertHotelManageFeeDetails: `${crsPrefix}/settle/managementFeeMaintenance/insertHotelManageFeeDetails`,
  // 修改管理费
  updateHotelManageFeeDetails: `${crsPrefix}/settle/managementFeeMaintenance/updateHotelManageFeeDetails`,
  // 获取管理费账单
  queryCostBillList: `${crsPrefix}/costbill/queryCostBillList`,
  // 修改管理费账单状态
  updateCostBillStatus: `${crsPrefix}/costbill/updateCostBillStatus`,
  // 手动调账
  manualReconciliation: `${crsPrefix}/costbill/manualReconciliation`,
  // 获取订单列表
  getList: `${crsPrefix}/order/getList`,
  getOrders: `${rezenPrefix}/mall/order/getOrders`,
  // 订单确认
  confirm: `${crsPrefix}/order/confirm`,
  // 拒单
  reject: `${crsPrefix}/order/reject`,
  // 获取订单详情
  getDetail: `${crsPrefix}/order/getDetail`,
  // 获取PMS失败原因
  getOrderFailedReason: `${crsPrefix}/order/getOrderFailedReason`,
  // 查询酒店钟点房配置
  getCrsHourlyRoomConfig: `${crsPrefix}/merchant/crs/hourlyRoomConfig/getCrsHourlyRoomConfig`,
  getOrderDetail: `${rezenPrefix}/mall/order/getOrderDetail`,
  // 退款原因列表
  applyReasons: `${rezenPrefix}/mall/aftersale/applyReasons`,
  // 申请售后接口
  applyAfterSale: `${rezenPrefix}/mall/aftersale/applyAfterSale`,
  // 订单日志
  getLogs: `${rezenPrefix}/mall/order/getLogs`,
  getByProductIdAndSnap: `${rezenPrefix}/merchant/product/snapshot/getByProductIdAndSnap`,
  mallUpdateOrderFinanceRemark: `${rezenPrefix}/mall/order/updateOrderFinanceRemark`,
  getOrderRefundDetail: `${rezenPrefix}/mall/order/getOrderRefundDetail`,
  getAfterSaleButtonType: `${rezenPrefix}/mall/aftersale/getAfterSaleButtonType`,


  // 查询话题列表
  queryDiscussTopic: `${crsPrefix}/crsbizgeneral/discussTopic/queryDiscussTopic`,
  // 查询话题详情
  getDiscussTopicDetail: `${crsPrefix}/crsbizgeneral/discussTopic/getDiscussTopicDetail`,
  // 新增话题
  addDiscussTopic: `${crsPrefix}/crsbizgeneral/discussTopic/addDiscussTopic`,
  // 话题图片上传
  uploadImageTopic: `${crsPrefix}/crsbizgeneral/discussTopic/uploadImage`,
  // 添加第三方关联
  addThirdPartRelation: `${crsPrefix}/crsbizgeneral/discussTopic/addThirdPartRelation`,
  // 添加评论
  addTopicComment: `${crsPrefix}/crsbizgeneral/discussTopic/addTopicComment`,
  // 删除评论
  deleteTopicComment: `${crsPrefix}/crsbizgeneral/discussTopic/deleteTopicComment`,
  // 删除话题
  deleteDiscussTopic: `${crsPrefix}/crsbizgeneral/discussTopic/deleteDiscussTopic`,
  // 查询评论列表
  queryTopicComment: `${crsPrefix}/crsbizgeneral/discussTopic/queryTopicComment`,
  // 解除第三方关联
  removeThirdPartRelation: `${crsPrefix}/crsbizgeneral/discussTopic/removeThirdPartRelation`,
  // 编辑话题
  updateDiscussTopic: `${crsPrefix}/crsbizgeneral/discussTopic/updateDiscussTopic`,
  // 查询第三方Topic优先级
  queryThirdPartTopicPriority: `${crsPrefix}/crsbizgeneral/thirdPartTopic/queryThirdPartTopicPriority`,
  // 查询第三方用户列表
  queryThirdPartUser: `${crsPrefix}/crsbizgeneral/thirdPartTopic/queryThirdPartUser`,
  // 查询第三方工作流状态映射
  queryThirdPartWorkflowStatusMap: `${crsPrefix}/crsbizgeneral/thirdPartTopic/queryThirdPartWorkflowStatusMap`,
  // 查询第三方需求类别
  queryThirdPartWorkitemType: `${crsPrefix}/crsbizgeneral/thirdPartTopic/queryThirdPartWorkitemType`,
  // 更新第三方工作Topic状态
  updateThirdPartTopicStatus: `${crsPrefix}/crsbizgeneral/thirdPartTopic/updateThirdPartTopicStatus`,


  // 获取账户列表
  getGroupSettlementAccountFeeList: `${crsPrefix}/groupSettlement/getGroupSettlementAccountFeeList`,
  // 获取账户汇总信息
  getGroupSettlementAccountFeesTotal: `${crsPrefix}/groupSettlement/getGroupSettlementAccountFeesTotal`,
  // 获取账户信息
  getAccountWithContact: `${crsPrefix}/hotel/receivableAccount/getAccountWithContact`,
  // 修改账户信息
  updateAccountWithContact: `${crsPrefix}/hotel/receivableAccount/updateAccountWithContact`,
  // 获取账单汇总和列表
  getSettlementBillList: `${crsPrefix}/groupSettlement/getSettlementBillList`,
  // 获取账单下单的订单列表
  getSettlementAccountList: `${crsPrefix}/groupSettlement/getSettlementAccountList`,
  // 获取应收帐账单列表
  getAccountReceivableAccount: `${crsPrefix}/groupSettlement/getAccountReceivableAccount`,
  // 获取兑现明细
  getCashDetail: `${crsPrefix}/groupSettlement/getCashDetail`,
  // 获取遗留可兑现金额
  getRemainingReceiptAmount: `${crsPrefix}/groupSettlement/getRemainingReceiptAmount`,
  // 兑现
  managementFeeCashing: `${crsPrefix}/groupSettlement/managementFeeCashing`,
  // 获取应收帐管理费详单
  getManagementFeeDetails: `${crsPrefix}/groupSettlement/getManagementFeeDetails`,
  // 获取未入账账单
  notInAccountBillList: `${crsPrefix}/bill/notInAccountBillList`,
  // 获取已入账账单列表
  accountBillList: `${crsPrefix}/bill/accountBillList`,
  // 获取已入账账单详情
  accountBillDetailList: `${crsPrefix}/bill/accountBillDetailList`,
  // 获取预售券账单列表
  preSaleCouponsAmountList: `${crsPrefix}/bill/preSaleCouponsAmountList`,
  // 获取账户余额
  getHotelAccountBalance: `${crsPrefix}/hotel/account/getHotelAccountBalance`,
  // 获取账号收支详情
  getHotelSumIncomeAndCost: `${crsPrefix}/hotel/account/getHotelSumIncomeAndCost`,
  // 获取账户详情
  getHotelAccountDetail: `${crsPrefix}/hotel/account/getHotelAccountDetail`,
  // 获取账户提现记录
  getHotelWithdrawRecord: `${crsPrefix}/hotel/account/getHotelWithdrawRecord`,
  // 获取待提现账单列表
  getHotelUnWithdrawBillList: `${crsPrefix}/hotel/account/getHotelUnWithdrawBillList`,
  // 账户提现
  hotelWithdraw: `${crsPrefix}/hotel/account/hotelWithdraw`,
  // 获取订单日志
  getLog: `${crsPrefix}/order/getLog`,
  // 取消订单
  cancle: `${crsPrefix}/order/cancle`,
  // 订单退款
  refund: `${crsPrefix}/order/refund`,
  // 订单部分退款
  partRefund: `${crsPrefix}/order/partRefund`,
  // 设置订单状态
  manualReview: `${crsPrefix}/order/manualReview`,
  // 下载管理费详单
  downloadCostBillList: `${crsPrefix}/costbill/downloadCostBillList`,
  // 下载已入账账单列表
  downloadAccBillDetList: `${crsPrefix}/bill/downloadAccBillDetList`,
  // 下载订单列表
  downloadList: `${crsPrefix}/order/downloadList`,
  // POST 查看明文手机号
  getRealPhoneNumber: `${crsPrefix}/order/getRealPhoneNumber`,
  // 下载结算中心账户列表
  downloadAccountRecordList: `${crsPrefix}/groupSettlement/downloadAccountRecordList`,
  // 下载账单列表
  downloadBillList: `${crsPrefix}/groupSettlement/downloadBillList`,
  // 下载应付帐详单列表
  downloadSettlementAccountList: `${crsPrefix}/groupSettlement/downloadSettlementAccountList`,
  // 下载应收帐列表
  downloadReceivableAccountList: `${crsPrefix}/groupSettlement/downloadReceivableAccountList`,
  // POST 对账单列表查询
  getStatementList: `${crsPrefix}/statement/getStatementList`,
  // POST 对账单明细查询
  getStatementDetail: `${crsPrefix}/statement/getStatementDetail`,
  // POST 对账单审核操作
  operateStatementStatus: `${crsPrefix}/statement/operateStatementStatus`,
  // 获取订单来源渠道列表
  getOrderChannelGroupList: `${crsPrefix}/order/getOrderChannelGroupList`,
  // POST 不结算订单明细列表查询
  getNotSettleOrderDetail: `${crsPrefix}/statement/getNotSettleOrderDetail`,
  // POST 交叉奖励金查询
  getCrossRewardList: `${crsPrefix}/reward/getCrossRewardList`,
  // POST 交叉奖励金设置
  setCrossReward: `${crsPrefix}/reward/setCrossReward`,
  // POST 查看酒店奖励分成详情列表
  hotelAwardSplitList: `${crsPrefix}/bill/hotelAwardSplitList`,
  // POST 现付对账单改单
  updateStatementDetails: `${crsPrefix}/statement/updateStatementDetails`,
  // POST 修改订单财务备注
  updateOrderFinanceRemark: `${crsPrefix}/order/updateOrderFinanceRemark`,
  // /merchant/dc/getHotelDirectFlag
  getHotelDirectFlag: `${crsPrefix}/merchant/dc/getHotelDirectFlag`,
  // POST 获取订单升房房型列表
  getUpgradeRoomTypeList: `${crsPrefix}/order/getUpgradeRoomTypeList`,
  // POST 非直连订单升房
  upgradeOrderRoomType: `${crsPrefix}/order/upgradeOrderRoomType`,
  // POST 分销对账单分销商下拉框列表
  // distributionStatementCheckBoxList: `${crsPrefix}/distribution/settle/distributionStatementCheckBoxList`,
  distributionStatementCheckBoxList: `${crsPrefix}/distribution/reconciliation/queryDistributorList`,
  getDistributionInfoByEntityId: `${crsPrefix}/merchant/ibe/distribution/getDistributionInfoByEntityId`,
  // POST 查询分销商的账期时间段
  queryDistributorAccountPeriodDateList: `${crsPrefix}/distribution/reconciliation/queryDistributorAccountPeriodDateList`,
  // POST 查询分销商对账单列表-api
  // queryDistributionSettlementList: `${crsPrefix}/distribution/settle/queryDistributionSettlementList`,
  queryDistributionSettlementList: `${crsPrefix}/distribution/reconciliation/queryDistributionStatementList`,
  // POST 修改分销商对账单的对账状态
  // updateDistributionSettlementStatus: `${crsPrefix}/distribution/settle/updateDistributionSettlementStatus`,
  updateDistributionSettlementStatus: `${crsPrefix}/distribution/reconciliation/setDistributionStatementStatus`,
  // POST 账单管理-查询渠道账单列表
  queryChannelBillList: `${crsPrefix}/distribution/settle/queryChannelBillList`,
  // POST 账单管理-查询渠道账单明细
  queryChannelettlementDetailsByDateList: `${crsPrefix}/distribution/settle/queryChannelettlementDetailsByDateList`,
  // POST 获取分销商对账单详情列表
  // queryDistributionSettlementDetailList: `${crsPrefix}/distribution/settle/queryDistributionSettlementDetailList`,
  queryDistributionSettlementDetailList: `${crsPrefix}/distribution/reconciliation/queryDistributionStatementDetailsList`,
  // POST 单个/批量修改对账单详情的结算状态，并输入备注内容
  // updateDistributionSettlementDetailStatus: `${crsPrefix}/distribution/settle/updateDistributionSettlementDetailStatus`,
  updateDistributionSettlementDetailStatus: `${crsPrefix}/distribution/reconciliation/updateDistributionStatementDetailsSettleStatus`,
  // POST 查看对账单详情调整历史
  queryDistributionSettlementDetailHistory: `${crsPrefix}/distribution/settle/queryDistributionSettlementDetailHistory`,
  // POST 分销对账单导入
  // importDistributionSettlement: `${crsPrefix}/distribution/settle/importDistributionSettlement`,
  importDistributionSettlement: `${crsPrefix}/distribution/reconciliation/importDistributionSettlement`,
  // POST 对账单导入历史查询
  // queryDistributionSettlementImportRecord: `${crsPrefix}/distribution/settle/queryDistributionSettlementImportRecord`,
  queryDistributionSettlementImportRecord: `${crsPrefix}/distribution/reconciliation/queryDistributionOrderFileImportRecord`,
  // POST 查询对账单详情页详情数据//新的接口外层可以带入不在使用接口获取数据
  // queryEditDistributionStatementDetailsData: `${crsPrefix}/distribution/settle/queryEditDistributionStatementDetailsData`,
  // 查询渠道侧分销预付订单详情
  queryDistributionStatementDetailsInfo: `${crsPrefix}/distribution/reconciliation/queryDistributionStatementDetailsInfo`,

  // POST 修改对账单数据
  updateDistributionStatementDetailsData: `${crsPrefix}/distribution/settle/updateDistributionStatementDetailsData`,
  // POST 账单管理-批量/单个操作分销对账单是否结算给酒店
  updateDistributionSettleDetailsStatus: `${crsPrefix}/distribution/settle/updateDistributionSettleDetailsStatus`,
  // POST 账单管理-将结算订单批量发送给酒店
  sendDistributionSettlementToHotel: `${crsPrefix}/distribution/settle/sendDistributionSettlementToHotel`,
  // POST 账单管理-查询酒店账单发送历史
  queryHotelSettleSendHistory: `${crsPrefix}/distribution/settle/queryHotelSettleSendHistory`,
  // POST 酒店账单-按照周期查询酒店账单统计
  // queryHotelBillStatisticsByDate: `${crsPrefix}/distribution/settle/queryHotelBillStatisticsByDate`,
  queryHotelBillStatisticsByDate: `${crsPrefix}/distribution/reconciliation/selectHotelDistributionStatementStatusCount`,
  // POST 酒店账单-查看分销账单明细
  // queryHotelSettleDetails: `${crsPrefix}/distribution/settle/queryHotelSettleDetails`,
  queryHotelSettleDetails: `${crsPrefix}/distribution/reconciliation/selectHotelDistributionStatementDetails`,
  // 下载酒店分销报表明细
  downloadHotelDistributionStatementDetails1: `${crsPrefix}/distribution/reconciliation/downloadHotelDistributionStatementDetails`,
  // POST 账单管理-发送酒店账单—展示未发送数据明细
  queryUnSendDistributionSettleDetails: `${crsPrefix}/distribution/settle/queryUnSendDistributionSettleDetails`,
  // 酒店账单-异步导出分销预付酒店账单明细
  exportHotelStatementDetail: `${crsPrefix}/distribution/reconciliation/asyncExportHotelDistributionStatementDetail`,
  // 酒店账单-下载分销预付酒店账单明细
  downloadHotelDistributionStatementDetail: `${crsPrefix}/distribution/reconciliation/downloadHotelDistributionStatementDetail`,
  // 酒店账单-下载分销预付酒店账单明细
  queryHotelDistributionStatementDetailExportRecord: `${crsPrefix}/distribution/reconciliation/queryHotelDistributionStatementDetailExportRecord`,
  // POST 酒店账单-按照周期查询该周期内的酒店账单列表
  queryHotelBillListByDate: `${crsPrefix}/distribution/settle/queryHotelBillListByDate`,
  // POST 酒店账单-修改账单状态并进入可提现账户
  // updateHotelBillStatus: `${crsPrefix}/distribution/settle/updateHotelBillStatus`,
  updateHotelBillStatus: `${crsPrefix}/distribution/reconciliation/setHotelDistributionStatementStatus`,
  // POST 集团端-酒店对账审核-分销对账单列表
  // queryDistributionStatementCheckResult: `${crsPrefix}/distribution/settle/queryDistributionStatementCheckResult`,
  queryDistributionStatementCheckResult: `${crsPrefix}/distribution/reconciliation/selectHotelDistributionStatementSummaryCheckList`,
  // POST 集团端-酒店对账单-分销对账单-查看账单
  // queryDistributionStatementDetails: `${crsPrefix}/distribution/settle/queryDistributionStatementDetails`,
  queryDistributionStatementDetails: `${crsPrefix}/distribution/reconciliation/selectHotelDistributionStatementDetailsCheckList`,
  // POST 集团端-查询分销账单酒店审核状态列表
  // selectDistributionHotelCheckStatusList: `${crsPrefix}/distribution/settle/selectDistributionHotelCheckStatusList`,
  selectDistributionHotelCheckStatusList: `${crsPrefix}/distribution/reconciliation/selectHotelDistributionStatementCheckList`,
  // POST 集团端-酒店对账单-分销对账单-集团审核对账单
  // updateGroupSettleStatus: `${crsPrefix}/distribution/settle/updateGroupSettleStatus`,
  updateGroupSettleStatus: `${crsPrefix}/distribution/reconciliation/updateStatementDetailsGroupStatus`,
  // POST 集团端-酒店对账单-分销对账单-确认发送审核结果
  // groupConfirmsSendCheckResult: `${crsPrefix}/distribution/settle/groupConfirmsSendCheckResult`,
  groupConfirmsSendCheckResult: `${crsPrefix}/distribution/reconciliation/groupConfirmStatementCheckStatus`,
  // POST 酒店端-查询分销对账单列表
  selectDistributionStatementList: `${crsPrefix}/distribution/settle/selectDistributionStatementList`,
  // POST 酒店端-查询分销对账单明细列表
  selectDistributionDetailList: `${crsPrefix}/distribution/settle/selectDistributionDetailList`,
  // POST 酒店端-对账单审核操作
  operateDistributionStatement: `${crsPrefix}/distribution/settle/operateDistributionStatement`,
  // POST 酒店端-批量操作对账单详情
  batchOperateDistributionDetail: `${crsPrefix}/distribution/settle/batchOperateDistributionDetail`,
  // POST 酒店端-批量确认分销对账单明细无争议
  batchConfirmDistributionDetailNoDispute: `${crsPrefix}/distribution/settle/batchConfirmDistributionDetailNoDispute`,
  // POST 酒店端-发票管理-酒店上传发票等信息
  hotelUploadsInvoiceInfo: `${crsPrefix}/distribution/settle/hotelUploadsInvoiceInfo`,
  // POST 上传发票图片
  uploadImage: `${crsPrefix}/distribution/settle/uploadImage`,
  // POST 发票管理-HQ上传发票等信息
  rezenUploadsInvoiceInfo: `${crsPrefix}/distribution/settle/rezenUploadsInvoiceInfo`,
   // POST 丽呈发票下载-api
   downloadDistributionStatementRezenInvoiceZip: `${crsPrefix}/distribution/settle/downloadDistributionStatementRezenInvoiceZip`,
  // POST 丽呈发票上传-api
  rezenSelectInvoiceInfoList: `${crsPrefix}/distribution/settle/rezenSelectInvoiceInfoList`,
   
   
  // POST 导入客服备注
  importCustomerServiceRemark: `${crsPrefix}/distribution/settle/importCustomerServiceRemark`,
  // POST 查询酒店对账单详情操作历史
  queryHotelSettlementDetailHistory: `${crsPrefix}/distribution/settle/queryHotelSettlementDetailHistory`,
  // POST 订单正常入账结算
  directionOrderNormalSettle: `${crsPrefix}/order/directionOrderNormalSettle`,
  // POST 查询分销对账单间夜明细信息
  // groupSelectDistributionStatementDetailsRoomNightInfo: `${crsPrefix}/distribution/settle/groupSelectDistributionStatementDetailsRoomNightInfo`,
  queryDistributionStatementDetailsNightInfo: `${crsPrefix}/distribution/reconciliation/queryDistributionStatementDetailsNightInfo`,
  queryHistoricalOrderNightInfo: `${crsPrefix}/distribution/reconciliation/queryHistoricalOrderNightInfo`,
  // 酒店账单-间夜弹框
  queryHotelStatementDetailsNightInfo: `${crsPrefix}/distribution/reconciliation/queryHotelStatementDetailsNightInfo`,
  // 酒店账单-修改间夜
  updateHotelStatementDetailsNightInfo: `${crsPrefix}/distribution/reconciliation/updateHotelStatementDetailsNightInfo`,
  // POST 编辑分销对账单间夜明细信息
  // groupEditDistributionStatementDetailsRoomNightInfo: `${crsPrefix}/distribution/settle/groupEditDistributionStatementDetailsRoomNightInfo`,
  updateDistributionStatementDetailsNightInfo: `${crsPrefix}/distribution/reconciliation/updateDistributionStatementDetailsNightInfo`,
  // 非本账期的-修改间夜
  updateHistoricalOrderInfo: `${crsPrefix}/distribution/reconciliation/updateHistoricalOrderInfo`,
  // POST 酒店端-分销对账单明细下载
  downloadHotelDistributionStatementDetails: `${crsPrefix}/distribution/settle/downloadHotelDistributionStatementDetails`,
  // POST 分销渠道取消订单
  distributionCancelOrder: `${crsPrefix}/order/distributionCancelOrder`,


  // 智能升房
  // 获取智能房型列表
  upgradeRoomList: `${crsPrefix}/merchant/intelligent/upgradeRoomList`,
  // 获取可升级房型列表
  getCanUpgradeRoomList: `${crsPrefix}/merchant/intelligent/getCanUpgradeRoomList`,
  // 获取智能升房高级设置
  upgradeRoomSet: `${crsPrefix}/merchant/intelligent/upgradeRoomSet`,
  // 保存智能升房房型配置
  saveUpgradeRoomConfig: `${crsPrefix}/merchant/intelligent/saveUpgradeRoomConfig`,
  // 获取智能升房房型配置详情
  upgradeRoomConfigDetail: `${crsPrefix}/merchant/intelligent/upgradeRoomConfigDetail`,
  // 获取智能升房房型配置详情-下拉列表接口
  getCanUpgradeRoomSelectList: `${crsPrefix}/merchant/intelligent/getCanUpgradeRoomSelectList`,
  // 集团端-下载分销账单明细excel模版
  // downloadDistributionStatementDetailsModel: `${crsPrefix}/distribution/settle/downloadDistributionStatementDetailsModel`,
  downloadDistributionStatementDetailsModel: `${crsPrefix}/distribution/reconciliation/downloadDistributionOrderFileModel`,
  // 下载分销对账单明细数据
  downloadDistributionStatementDetails: `${crsPrefix}/distribution/reconciliation/downloadDistributionStatementDetails`,
  // 分销渠道对账根据状态查询符合条件的酒店账单
  // groupSelectHotelStatementByStatusAndHotelName: `${crsPrefix}/distribution/settle/groupSelectHotelStatementByStatusAndHotelName`,
  groupSelectHotelStatementByStatusAndHotelName: `${crsPrefix}/distribution/reconciliation/selectHotelDistributionStatementList`,
  // 分销对账单审核根据争议状态查询酒店列表
  groupSelectOrderHotelList: `${crsPrefix}/distribution/settle/groupSelectOrderHotelList`,
  // POST 查询往期本渠道历史订单
  // selectPastDistributionStatementDetails: `${crsPrefix}/distribution/settle/selectPastDistributionStatementDetails`,
  selectPastDistributionStatementDetails: `${crsPrefix}/distribution/reconciliation/queryHistoricalOrderInfo`,
  // POST 调整本渠道跨月账单
  editCrossDistributionStatementDetails: `${crsPrefix}/distribution/settle/editCrossDistributionStatementDetails`,
  // POST 酒店端发票管理查询发票信息
  hotelSelectInvoiceInfoList: `${crsPrefix}/distribution/settle/hotelSelectInvoiceInfoList`,
  // POST 下载发票
  downloadDistributionStatementInvoiceZip: `${crsPrefix}/distribution/settle/downloadDistributionStatementInvoiceZip`,
  // POST 集团端 - 查询酒店抵扣汇总
  selectHotelDeductionSummaryList: `${crsPrefix}/fee/deduction/selectHotelDeductionSummaryList`,
  // POST 酒店抵扣策略列表
  getHotelDeductionInfoList: `${crsPrefix}/merchant/hotel/expense/selectHotelDeductionStrategyList`,
  // getHotelDeductionInfoList: `${crsPrefix}/fee/deduction/getHotelDeductionInfoList`,
  // POST 酒店抵扣策略详情
  getHotelDeductionInfoDetail: `${crsPrefix}/merchant/hotel/expense/selectHotelDeductionStrategyDetails`,
  // getHotelDeductionInfoDetail: `${crsPrefix}/fee/deduction/getHotelDeductionInfoDetail`,

  // 修改酒店抵扣策略规则(之前的修改加盟，管理集采统一用这个)
  updateHotelDeductionStrategyRules: `${crsPrefix}/merchant/hotel/expense/updateHotelDeductionStrategyRules`,
  // POST 修改加盟费
  updateJoinFee: `${crsPrefix}/fee/deduction/updateJoinFee`,
  // POST 修改管理费
  updateManageFee: `${crsPrefix}/fee/deduction/updateManageFee`,
  // POST 修改集采费
  updateGroupPurchaseFee: `${crsPrefix}/fee/deduction/updateGroupPurchaseFee`,
  // POST 查询酒店账户冻结信息
  getHotelAccountBalanceFrozen: `${crsPrefix}/merchant/hotel/expense/selectHotelAccountStatusInfo`,
  // getHotelAccountBalanceFrozen: `${crsPrefix}/hotel/account/getHotelAccountBalanceFrozen`,

  // POST 设置酒店账户状态
  setHotelAccountBalanceFrozenStatus: `${crsPrefix}/merchant/hotel/expense/updateHotelAccountStatus`,
  // setHotelAccountBalanceFrozenStatus: `${crsPrefix}/hotel/account/setHotelAccountBalanceFrozenStatus`,
  // POST 酒店账户余额抵扣页面信息
  hotelAccountDeductionPage: `${crsPrefix}/merchant/hotel/expense/selectHotelAccountBalanceDeductionPageInfo`,
  // hotelAccountDeductionPage: `${crsPrefix}/fee/deduction/hotelAccountDeductionPage`,
  // POST 酒店账户余额抵扣
  hotelAccountDeduction: `${crsPrefix}/merchant/hotel/expense/deductHotelAccountBalance`,
  // hotelAccountDeduction: `${crsPrefix}/fee/deduction/hotelAccountDeduction`,
  // POST 查询客房订单列表
  getRoomOrderList: `${crsPrefix}/order/getRoomOrderList`,
  // POST 获取集团渠道
  getCrsChannelList: `${crsPrefix}/merchant/crs/channel/getCrsChannelList`,
  getCrsChannelListByScene: `${crsPrefix}/merchant/crs/channel/getCrsChannelListByScene`,
  // 获取开关标识
  getApplicationSwitch: `${crsPrefix}/applicationSwitchConfig/getApplicationSwitch`,
  // POST 客房订单导出
  downloadRoomOrderList: `${crsPrefix}/order/downloadRoomOrderList`,


  // POST 查询对账单明细置疑信息
  selectStatamentDetailsDisputeInfo: `${crsPrefix}/statement/selectStatamentDetailsDisputeInfo`,
  //  查询对账单明细操作历史接口
  selectStatementDetailsOperateHistory: `${crsPrefix}/statement/selectStatementDetailsOperateHistory`,
  // 现付对账单明细操作改单
  groupDisputeStatementDetails: `${crsPrefix}/statement/groupDisputeStatementDetails`,
  // 审核现付对账单明细
  groupCheckStatementDetails: `${crsPrefix}/statement/groupCheckStatementDetails`,
  // 集团提交对账单审核结果-直销
  groupConfirmStatementCheckStatusZx: `${crsPrefix}/statement/groupConfirmStatementCheckStatus`,

  // 集团端-导入订单间夜金额
  groupImportOrderNightDetails: `${crsPrefix}/distribution/settle/groupImportOrderNightDetails`,

  // 账单收件邮箱
  // 批量修改结算账户邮箱
  batchUpdateSettleAccountEmailAddress: `${crsPrefix}/settle/account/batchUpdateSettleAccountEmailAddress`,
  // 获取所有结算账户列表
  getSettleAccountList: `${crsPrefix}/settle/account/getSettleAccountListV1`,
  // 获取结算账户详情
  getSettleAccountDetail: `${crsPrefix}/settle/account/getSettleAccountDetail`,
  // 获取渠道账户列表接口
  getDistributionForSettle: `${crsPrefix}/settle/account/getDistributionForSettle`,
  // 新增修改渠道结算账户
  addOrModifyDistributionAccount: `${crsPrefix}/settle/account/addOrModifyDistributionAccountV1`,
  // 集团端-查询酒店抵扣明细列表
  selectHotelDeductionDetailsList: `${crsPrefix}/merchant/hotel/expense/selectHotelDeductionDetailsList`,
  // selectHotelDeductionDetailsList: `${crsPrefix}/fee/deduction/selectHotelDeductionDetailsList`,
  // 抵扣明细下载
  downloadDeductionDetails: `${crsPrefix}/merchant/hotel/expense/downloadHotelDeductionDetails`,
  // downloadDeductionDetails: `${crsPrefix}/fee/deduction/downloadDeductionDetails`,
  // crs收支明细查询
  getCrsIncomeAndCostDetails: `${crsPrefix}/settle/report/getCrsIncomeAndCostDetails`,
  // crs收支明细下载
  downloadCrsIncomeAndCostDetailsExcel: `${crsPrefix}/settle/report/getCrsIncomeAndCostDetailsExcel`,
  // 查询渠道订单明细
  getDistributionOrderDetails: `${crsPrefix}/settle/report/getDistributionOrderDetails`,
  // 渠道订单明细下载
  getDistributionOrderDetailsExcel: `${crsPrefix}/settle/report/getDistributionOrderDetailsExcel`,
  // 新增银行信息
  createBankInfo: `${crsPrefix}/merchant/hotel/bank/createBankInfo`,
  // 查询银行信息列表
  getBankInfoList: `${crsPrefix}/merchant/hotel/bank/getBankInfoList`,
  // 查询渠道账单列表
  selectDistributionChannelBillList: `${crsPrefix}/distribution/cash/selectDistributionChannelBillList`,
  // 修改渠道账单对账状态
  updateChannelBillStatementStatus: `${crsPrefix}/distribution/cash/updateChannelBillStatementStatus`,
  updateDistributionCashStatementStatus: `${crsPrefix}/distribution/cash/updateDistributionCashStatementStatus`,
  // 查询酒店账单列表
  selectDistributionHotelBillList: `${crsPrefix}/distribution/cash/selectDistributionHotelBillList`,
  // 查询酒店账单明细
  selectDistributionHotelBillDetails: `${crsPrefix}/distribution/cash/selectDistributionHotelBillDetails`,
  // 查询订单调整信息
  selectHotelBillDetailsAdjustInfo: `${crsPrefix}/distribution/cash/selectHotelBillDetailsAdjustInfo`,
  selectDistributionCashDetailsInfo: `${crsPrefix}/distribution/cash/selectDistributionCashDetailsInfo`,
  // 操作酒店账单明细
  operateHotelBillDetails: `${crsPrefix}/distribution/cash/operateHotelBillDetails`,
  // 调整账单
  adjustStatementDetails: `${crsPrefix}/distribution/cash/adjustStatementDetails`,
  // 查询订单记录
  selectDistributionOrderRecordInfo: `${crsPrefix}/distribution/cash/selectDistributionOrderRecordInfo`,
  // 根据订单号查询订单列表
  selectOrderListByOrderIds: `${crsPrefix}/distribution/cash/selectOrderListByOrderIds`,
  // 酒店账单查询账单明细操作日志
  selectHotelBillDetailsOperateLog: `${crsPrefix}/distribution/cash/selectHotelBillDetailsOperateLog`,
  // 查询渠道账单明细
  selectDistributionChannelBillDetails: `${crsPrefix}/distribution/cash/selectDistributionChannelBillDetails`,
  // 查询渠道账单操作日志
  selectChannelBillOperateLog: `${crsPrefix}/distribution/cash/selectChannelBillOperateLog`,
  // 查询渠道账单明细调整信息
  selectChannelBillDetailsAdjustmentInfo: `${crsPrefix}/distribution/cash/selectChannelBillDetailsAdjustmentInfo`,
  // 根据订单号查询渠道账单订单列表-api
  selectStatementOrderListByOrderIds: `${crsPrefix}/distribution/cash/selectStatementOrderListByOrderIds`,
  // 查询导入账单excel日志接口
  queryDistributionCashSettlementImportRecord: `${crsPrefix}/distribution/cash/queryDistributionCashSettlementImportRecord`,
  // 导入艺龙分销现付订单excel接口
  importDistributionCashSettlement: `${crsPrefix}/distribution/cash/importDistributionCashSettlement`,
  // 集团端 - 查询渠道账单操作日志
  queryChannelDistributionStatementLogList: `${crsPrefix}/distribution/cash/queryChannelDistributionStatementLogList`,
  // 集团端 - 渠道订单调整
  updateChannelStatementDetail: `${crsPrefix}/distribution/cash/updateChannelStatementDetail`,
  updateDistributionCashDetailsInfo: `${crsPrefix}/distribution/cash/updateDistributionCashDetailsInfo`,
  // 分页查询酒店信息根据结算银行账户信息
  getSettleHotelListForHotelBankByPager: `${crsPrefix}/hotel/account/getSettleHotelListForHotelBankByPager`,
  // 设置酒店账户个人账户状态
  setHotelAccountIndividualFlag: `${crsPrefix}/hotel/account/setHotelAccountIndividualFlag`,
  // 设置酒店自动提现开关
  batchSetHotelAutomaticWithdrawalFlag: `${crsPrefix}/hotel/account/batchSetHotelAutomaticWithdrawalFlag`,
   // 设置酒店自动提现开关-api
   setHotelAutomaticWithdrawalFlag: `${crsPrefix}/hotel/account/setHotelAutomaticWithdrawalFlag`,
  //获取所有酒店和品牌
  getAllHotelAndBrandList: `${crsPrefix}/hotel/account/getAllHotelAndBrandList`,
  // 获取当前用户修改权限
  getHotelAccountIndividualPersonStatus: `${crsPrefix}/hotel/account/getHotelAccountIndividualPersonStatus`,
  // 查询酒店银行账户变更记录
  getHotelBankChangeRecordList: `${crsPrefix}/hotel/account/getHotelBankChangeRecordList`,
  // 修改1分钱收款状态
  updateOneCentPaymentStatus: `${crsPrefix}/hotel/account/updateOneCentPaymentStatus`,
  // 下载变更函
  downloadChangeLetter: `${crsPrefix}/hotel/account/downloadChangeLetter`,
  // 新增渠道收支-api
  addChannelIncomeCostDetails: `${crsPrefix}/channelIncomeCost/addChannelIncomeCostDetails`,
  // 查询渠道收支详情-api
  selectChannelIncomeCostDetails: `${crsPrefix}/channelIncomeCost/selectChannelIncomeCostDetails`,
  // 查询渠道收支列表-api  API
  selectChannelIncomeCostList: `${crsPrefix}/channelIncomeCost/selectChannelIncomeCostList`,
  // 修改渠道收支-api  API
  updateChannelIncomeCostDetails: `${crsPrefix}/channelIncomeCost/updateChannelIncomeCostDetails`,
  // 查询分销渠道账单金额-api  API
  selectDistributionBillAmountForAccount: `${crsPrefix}/channelIncomeCost/selectDistributionBillAmountForAccount`,
  // 获取渠道账单不更新时间-api  API
  selectDistributionStatementNotUpdateTime: `${crsPrefix}/distribution/reconciliation/selectDistributionStatementNotUpdateTime`,
  // 查询offline渠道产品列表接口-api  API
  getRezenBusinessProductList: `${crsPrefix}/merchant/crs/product/getRezenBusinessProductList`,
  // 创建offline订单
  createOfflineOrder: `${crsPrefix}/offline/createOrder`,
  // 校验会员是否有效
  checkMemberStatus: `${crsPrefix}/offline/checkMemberStatus`,
  // 复制offline订单
  copyOrderDetail: `${crsPrefix}/offline/copyOrder`,
  // 更新发票信息接口 
  updateHotelAccountInvoiceInfo: `${crsPrefix}/hotel/account/updateHotelAccountInvoiceInfo`,
  // 获取发票配置信息接口 
  getHotelAccountConfig: `${crsPrefix}/hotel/account/getHotelAccountConfig`,

  // 查询当前汇率
  getExchangeRate: `${crsPrefix}/settle/exchangeRate/getExchangeRate`,
  // 查询汇率操作记录
  queryExchangeRate: `${crsPrefix}/settle/exchangeRate/queryExchangeRate`,
  // 设置汇率
  setExchangeRate: `${crsPrefix}/settle/exchangeRate/setExchangeRate`,

  // 账户领款
  // 查询用户KA数据权限
  getKaUserDataPermission: `${crsPrefix}/api/rezenKaData/getKaUserDataPermission`,
  // 查询已领款列表
  queryFinanceReceivedInfoList: `${crsPrefix}/finance/receivable/queryFinanceReceivedInfoList`,
  // 查询待领款列表
  queryFinanceToReceiveInfoList: `${crsPrefix}/finance/receivable/queryFinanceToReceiveInfoList`,
  // 查询酒店费项
  selectHotelFeeList: `${crsPrefix}/finance/receivable/selectHotelFeeList`,
  // 查询分销渠道费项
  selectDistributionBillList: `${crsPrefix}/finance/receivable/selectDistributionBillList`,
  // 确认领款
  confirmOccupying: `${crsPrefix}/finance/receivable/confirmOccupying`,
  // 查询资金流水池数据
  selectCapitalFlowPool: `${crsPrefix}/finance/receivable/selectCapitalFlowPool`,
  // 查询资金领款详情信息
  selectCapitalFlowOccupyDetails: `${crsPrefix}/finance/receivable/selectCapitalFlowOccupyDetails`,
  // 撤销领款
  repealOccupying: `${crsPrefix}/finance/receivable/repealOccupying`,
  // 同步至29331
  receivableOccupy: `${crsPrefix}/finance/receivable/receivableOccupy`,
  // pushOccupyDataToTrip: `${crsPrefix}/finance/receivable/pushOccupyDataToTrip`,
  // 白名单
  getFinanceWhiteList: `${crsPrefix}/finance/receivable/getFinanceWhiteList`,
  // 获取订单标签
  getOrderTagInfo: `${crsPrefix}/order/getOrderTagInfo`,
  // 查询预付佣金酒店账单列表
  queryHotelDistributionCommissionStatementList: `${crsPrefix}/distribution/commission/queryHotelDistributionCommissionStatementList`,
  // 查询预付佣金酒店账单明细列表
  queryHotelDistributionCommissionStatementDetailsList: `${crsPrefix}/distribution/commission/queryHotelDistributionCommissionStatementDetailsList`,
  // 查询预付佣金渠道账单列表
  queryChannelDistributionCommissionStatementList: `${crsPrefix}/distribution/commission/queryChannelDistributionCommissionStatementList`,
  // 查询预付佣金渠道账单明细列表
  queryChannelDistributionCommissionStatementDetailsList: `${crsPrefix}/distribution/commission/queryChannelDistributionCommissionStatementDetailsList`,
  // 修改预付佣金渠道账单的对账状态
  updateChannelDistributionCommissionStatementStatus: `${crsPrefix}/distribution/commission/updateChannelDistributionCommissionStatementStatus`,

  // 获取丽呈分佣账单列表
  getRezenCommissionStatementList: `${crsPrefix}/commission/statement/rezen/getRezenCommissionStatementList`,

  // 获取丽呈分佣账单列表 - api
  getAllPartnerList: `${crsPrefix}/union/partner/getAllPartnerList`,

  // 获取丽呈分佣账单明细列表
  getRezenCommissionStatementDetailsList: `${crsPrefix}/commission/statement/rezen/getRezenCommissionStatementDetailsList`,
  // 获取合作伙伴分佣账单列表
  getPartnerCommissionStatementList: `${crsPrefix}/commission/statement/rezen/getPartnerCommissionStatementList`,
  // 获取合作伙伴分佣账单明细列表
  getPartnerCommissionStatementDetailsList: `${crsPrefix}/commission/statement/rezen/getPartnerCommissionStatementDetailsList`,
  // 获取联盟成员小程序全量信息
  getAllGroups: `${crsPrefix}/union/partner/getAllGroups`,
  //下载丽呈分佣账单明细
  downRezenCommissionStatementDetails: `${crsPrefix}/commission/statement/rezen/downRezenCommissionStatementDetails`,
  // 下载合作伙伴分佣账单明细
  downPartnerCommissionStatementDetails: `${crsPrefix}/commission/statement/rezen/downPartnerCommissionStatementDetails`,
  // HQ端批量下载分润账单明细
  batchDownLoadRezenCommissionSharingDetails: `${crsPrefix}/commission/statement/partner/batchDownLoadRezenCommissionSharingDetails`,

  // 官网分销账单
  // 官网分销账单酒店账单列表查询
  queryHotelRezenDistributionStatementList: `${crsPrefix}/rezen/distribution/queryHotelRezenDistributionStatementList`,
  // 官网分销账单的导出记录和导出
  // 异步导出官网分销账单明细
  asyncExportRezenDistributionDetail: `${crsPrefix}/rezen/distribution/asyncExportRezenDistributionDetail`,
  // 官网分销账单导出记录
  queryRezenDistributionDetailExportRecord: `${crsPrefix}/rezen/distribution/queryRezenDistributionDetailExportRecord`,
  // 导出记录中的官网分销账单下载
  downloadOfficialWebsiteDistributionBillDetail: `${crsPrefix}/distribution/reconciliation/downloadHotelDistributionStatementDetail`,
  // 官网分销酒店账单查看账单详情
  queryHotelRezenDistributionStatementDetailList: `${crsPrefix}/rezen/distribution/queryHotelRezenDistributionStatementDetailList`,
  // 下载官网分销酒店账单明细
  downloadHotelRezenDistributionStatementDetailList: `${crsPrefix}/rezen/distribution/downloadHotelRezenDistributionStatementDetailList`,
  // 官网分销酒店账单调整账单根据订单号查询（调整前、调整后）
  queryHotelRezenDistributionStatementDetailListByOrderIds: `${crsPrefix}/rezen/distribution/queryHotelRezenDistributionStatementDetailListByOrderIds`,
  // 调整账单查询间夜信息
  getRezenDistributionOrderNightInfo: `${crsPrefix}/rezen/distribution/getRezenDistributionOrderNightInfo`,
  // 调整账单计算调整后下期酒店结算金额和服务费接口
  getRezenDistributionOrderSettleAmountToNextStatement: `${crsPrefix}/rezen/distribution/getRezenDistributionOrderSettleAmountToNextStatement`,
  // 调整历史查询
  getRezenDistributionOrderAdjustmentHistory: `${crsPrefix}/rezen/distribution/getRezenDistributionOrderAdjustmentHistory`,
  // 调整接口-api
  adjustRezenDistributionOrder: `${crsPrefix}/rezen/distribution/adjustRezenDistributionOrder`,
};

const rezenApiMapping = {
  // 获取酒店列表
  getUserRelationHotelsInfo: `${rezenPrefix}/merchant/product/hotel/getUserRelationHotelsInfo`,
  // 获取区域信息
  getDistricts: `${rezenPrefix}/district/getDistricts`,
  // 获取酒店列表
  listUnit: `${rezenPrefix}/unit/listUnit`,
  crsListUnit: `${crsPrefix}/unit/listUnit`,
};

const cbsApiMapping = {
  // 获取抵扣账单明细
  getDeductionDetails: `${cbsPrefix}/settlementOrder/deduction/getDeductionDetails`,
};

const pingAnApiMapping = {
  // 查询营销子账户充值记录
  pabQueryMarketingAccountRechargeList: `${crsPrefix}/external/pab/merchant/pabQueryMarketingAccountRechargeList`,
  // 查询平安功能性账户余额
  pabQueryFunctionAccountBalance: `${crsPrefix}/external/pab/merchant/pabQueryFunctionAccountBalance`,
  // 营销子账户充值
  pabMarketingAccountRecharge: `${crsPrefix}/external/pab/merchant/pabMarketingAccountRecharge`,

};
// api映射关系
export default {
  ...crsApiMapping,
  ...rezenApiMapping,
  ...cbsApiMapping,
  ...pingAnApiMapping,
};
