/*
 * @Descripttion:
 * @version:
 * @Author: zhaoxiang
 * @Date: 2020-02-19 14:00:47
 * @LastEditors: zhaoxiang
 * @LastEditTime: 2020-02-28 15:57:42
 */
import './set-public-path';
import Vue from 'vue';
import AvocadoLoadVue from 'avocado-load-vue';
import has from 'lodash/has';
import forEach from 'lodash/forEach';
import VueQuillEditor from 'vue-quill-editor';
import packageConfig from '../package.json';
import HttpUtil from './utils/http/http-util-promise';
import router from './router';
import { permissionButton, permissionDisButton } from './components/permission-button';
import App from './App.vue';
import { hasPermission } from './utils/common/permission';

Vue.config.productionTip = false;
Vue.prototype.$hasPermission = hasPermission;
Vue.prototype.$httpUtilSo = HttpUtil;

// 添加全局组件
Vue.use(permissionButton);
Vue.use(permissionDisButton);
Vue.use(VueQuillEditor /* { default global options } */);

// 设置路由生命周期方法
if (has(Vue.prototype, '$routerHook')) {
  forEach(Vue.prototype.$routerHook, (item, index) => {
    router[index](item);
  });
}

// 使用Avocado创建生命周期对象
const vueLifecycles = AvocadoLoadVue({
  Vue,
  rootDomId: 'views',
  // 应用节点名称
  appName: packageConfig.name,
  appOptions: {
    render: h => h(App),
    router,
  },
});

export const { bootstrap } = vueLifecycles;
export const { mount } = vueLifecycles;
export const { unmount } = vueLifecycles;
export const { update } = vueLifecycles;
